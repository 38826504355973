@import "colour";
@import "font";
@import "variables";

:deep(.v-card-heading) {
  background: $v-color-lighter-grey;
}

.v-image-gallery-title {
  font-size: $v-font-size-xlarge;
  font-weight: $v-font-weight-bold;
  padding-left: 15px;
}

.v-image-gallery-header-1 {
  font-size: $v-font-size-xlarge;
}

.v-image-gallery-header-2 {
  font-size: $v-font-size-large;
}

.v-image-gallery-text {
  font-size: $v-font-size-medium;
}

.v-image-gallery-text-2 {
  font-size: $v-font-size-standard;
}

.v-image-gallery-bold {
  font-weight: $v-font-weight-bold;
}

.v-image-gallery-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.v-image-gallery-column {
  display: flex;
  flex-direction: column;
}

.v-image-gallery-report-button {
  font-size: $v-font-size-large;
  background-color: $v-color-teal;
  border-color: $v-color-teal;
  color: $v-color-black;
  padding: 0.5em 1em;
}

.v-image-gallery-separator {
  border-color: $v-color-cool-grey;
  width: 100%;
  height: 1px;
}

:deep(.v-image-gallery-link) {
  cursor: pointer;
  text-decoration: underline;
  width: fit-content;
}

:deep(.v-image-gallery-tooltip::after) {
  max-width: fit-content;
}

.v-is-sensitive-wrapper {
  cursor: pointer;
  margin-right: auto;
}

.justify-content-end {
  justify-content: end;
}

.column-gap-1em {
  column-gap: 1em;
}

.v-image-gallery-desc {
  @media screen and (max-width: $v-screen-sm) {
    flex-direction: column;
    align-items: center;

    .v-is-sensitive-wrapper {
      height: 56px;
      justify-content: center;
      order: 1;
      border-top: 1px solid $v-color-cool-grey;
      border-bottom: 1px solid $v-color-cool-grey;
      padding: 0 16px;
      width: 100%;
    }
  }
}

.v-image-gallery-report {
  @media screen and (max-width: $v-screen-sm) {
    flex-direction: column;
    text-align: center;
    border-top: 1px solid $v-color-cool-grey;
    border-bottom: 1px solid $v-color-cool-grey;
    padding: 16px;
    margin-bottom: 24px;

    button {
      margin-top: 16px;
    }
  }
}

.v-image-gallery-images {
  :deep(.v-picture-thumbnail-wrapper),
  :deep(.v-picture-thumbnail) {
    @media screen and (max-width: $v-screen-sm) {
      width: 100%;
      height: auto;

      .v-blurred-picture-cover {
        height: 225px;
      }
    }
  }
}
