@import './variables.scss';

.v-label {
  position: relative;
  align-items: center;
  height: 50px;
  line-height: 50px;
  color: $v-color-text-secondary;
  font-size: $v-font-size-standard;
  font-weight: 100;
  width: 80px;
  text-align: -webkit-left;
  margin: 0;
}

textarea::placeholder, input::placeholder {
  color: $v-color-text-secondary;
  font-size: inherit;
  font-weight: 100;
}

.v-required::after {
  content: "*";
  position: absolute;
  top: 0;
}

.v-border {
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc !important;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1) !important;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.v-no-border {
  border: none;
}

.border:focus, .border.is-open {
  border-color: #66cccc !important;
}

.v-input-wrapper {
  padding: 0;
  display: flex;
  width: 100%;
  align-items: center;

  input,
  .dp__input {
    height: 40px;
    border-radius: 4px;
    font-weight: 400;
    outline: 0;
    color: $v-color-text-primary;
    line-height: 24px;
    padding: 8px;
    font-size: $v-font-size-medium;

    &.multiselect-fake-input {
      display: none;
    }

    &:not(.dp__input) {
      padding: 8px;
    }

    &.v-input-icon {
      padding-left: 34px;
    }

    &.v-checkbox {
      padding: 0;
    }
  }

  .v-date-icon {
    .dp__input {
      padding-left: 36px;
    }
  }

  input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-family: $font-family-base;
    opacity: 1; /* Firefox */
  }

  input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    font-family: $font-family-base;
  }

  input::-ms-input-placeholder { /* Microsoft Edge */
    font-family: $font-family-base;
  }
}

.v-error {
  color: $v-color-red;
  padding-top: 3px;
  height: 15px;
}

.v-warning-text {
  line-height: 1.2;
  color: $v-color-red;
  font-size: 12px;
  box-sizing: border-box;
  outline: none;
  &:empty::before {
    content: "\A0";
  }
}

.input-error {
  border-color: $v-color-red !important;
}

.v-standard-input-wrapper {
  padding: 8px 0;
  min-height: 64px;
  height: fit-content;
}

.v-standard-input {
  @extend .v-border
}

.v-label.v-standard-input-label  {
  font-size: $v-font-size-large;
  color: black;
  font-weight: $v-font-weight-bold;
  min-width: 200px;
  text-align: end;
  margin-right: 32px;
}
