
.v-params-popup {
  box-sizing: border-box;
  border: 3px solid var(--colour-styles-primary-dark-grey-accessible, #57676D);
}

.v-conflict-management {
  display: flex;
  min-height: 56px;
  padding: var(--Spacing-Small, 8px) var(--Sizing-Scale-Small, 8px);
  justify-content: space-between;
  align-items: center;
  align-content: center;
  row-gap: var(--Spacing-Normal, 16px);
  align-self: stretch;
  flex-wrap: wrap;
  border-bottom: 1px solid #dad9d6;
}

.v-button-container {
  display: flex;
  gap: 8px;
}

.v-update {
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--Colours-Action-Primary, #6CC);
  background: var(--Colours-Action-Primary, #6CC);
}

.v-gap {
  background: var(--colour-styles-primary-dark-grey-accessible, #57676D);
}

.v-saved-by-you {
  display: inline-flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 0px 8px 8px 0px;
  margin: 3px 0 0 0;
  background: var(--Colour-Styles-Primary-White, #FFF);
}

/* Override tooltip max width */
.v-icon-tooltip {
  --tooltip-max-width: 100em;
}
