@import './colour.scss';
@import './font.scss';

// NEW VARIABLES

$v-border-radius: 4px;
$v-line-height-base: 24px;


// Container sizes
$v-navbar-height:                 56px;
$v-maintenance-banner-height:     50px;
$v-top-actions-bar-height:        48px;
$v-top-actions-bar-height-sm:     85px;
$v-double-panel-left-width:       392px;
$v-double-panel-left-width-sm:    330px;
$v-double-panel-left-height:      calc(100vh - #{$v-navbar-height} - #{$v-top-actions-bar-height});
$v-double-panel-right-width:      calc(100% - #{$v-double-panel-left-width});
$v-double-panel-right-width-sm:   calc(100% - #{$v-double-panel-left-width-sm});
$v-alarms-button-width:           75px;
$v-alarms-button-width-sm:        25px;

// Drop Shadow
$v-default-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);

// Screen sizes
$v-screen-xs:                  480px !default;
$v-screen-xs-min:              $v-screen-xs !default;
$v-screen-phone:               $v-screen-xs-min !default;

$v-screen-sm:                  800px !default;
$v-screen-sm:                  $v-screen-sm !default;
$v-screen-tablet:              $v-screen-sm !default;

$v-screen-md:                  992px !default;
$v-screen-md-min:              $v-screen-md !default;
$v-screen-desktop:             $v-screen-md-min !default;

$v-screen-lg:                  1200px !default;
$v-screen-lg-min:              $v-screen-lg !default;
$v-screen-lg-desktop:          $v-screen-lg-min !default;

$v-screen-xs-max:              ($v-screen-sm - 1) !default;
$v-screen-sm-max:              ($v-screen-md-min - 1) !default;
$v-screen-md-max:              ($v-screen-lg-min - 1) !default;

$v-base-size: 4px;

$v-mobile-nav-breakpoint: $v-screen-sm;
$v-line-height-base: 1.2;
$v-line-height-base: 24px;

$v-baseline: 20px;

/* FONTS */
$v-font-size-medium: 16px;
$v-icon-size: 20px;
$v-font-size-14: 14px;
$v-font-size-18: 18px;

/* FADE */
$v-fade-start-color: rgba(31,41,46,0.8);
$v-fade-end-color: white;




// OLD VARIABLES

.clearfix {
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}




/* COMPONENTS COLOR */
$action-icon-color:       $v-color-dark-grey;
$table-border-color:      $v-color-light-grey;
$list-entry-hover-color:  darken($v-color-light-grey, 2%);  /* #F7F6F3 */
$even-row-bg-color:       $v-color-light-grey;
$dropdown-hover-color:    rgba($v-color-teal, 0.2);
$spinner-icon-color:      $v-color-mid-grey;

/* ALERT COLORS */
$alert-info-bg:         $v-color-black;
$alert-success-bg:      $v-color-green;
$alert-warning-bg:      $v-color-orange;
$alert-danger-bg:       $v-color-red;

/* FONTS */
$font-size-small: 12px; // 14 * 0.85
$font-size-xl: 48px;
$font-size-h1: 32px;
$font-size-h2: 26px;
$font-size-h3: 20px;
$font-size-medium: $v-font-size-medium;
$font-family-sans-serif: "FS Me", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-base: $font-family-sans-serif;

/* GRIDS */
$grid-gutter-width: 10px;
$grid-float-breakpoint: 0; /* Disable navbar responsiveness */

$panel-heading-padding-vt: $grid-gutter-width;
$panel-heading-padding-hz: $grid-gutter-width;
$panel-heading-padding: $panel-heading-padding-vt $panel-heading-padding-hz;

$padding-vt: $v-baseline;
$padding-vt-sm: calc(#{$padding-vt} / 2);
$padding-hz: calc(#{$grid-gutter-width} * 2);
$padding-hz-sm: calc(#{$padding-hz} / 4);

/* CONTAINERS SIZES */
$maintenance-banner-height:     50px;
$bottom-actions-bar-height:     38px;
$sidebar-width:                 264px;
$sidebar-width-minimized:       56px;
$sidebar-sub-menu-height:       46px;


$form-icon-col-width: 60px;

$loader-size: 90px;

/* MODALS SIZE */
$modal-large:  750px;
$modal-medium: 600px;
$modal-small:  450px;

/* BUTTONS */
$sm-round-btn: 55px;
$md-round-btn: 100px;
$md-standard-height: 45px;

/* ICON SIZE */
$icon-size-md: $v-baseline*1.5;
$icon-size-lg: 36px;


/* ICON HOVER SIZES */
$icon-hover-small: $v-baseline*1.25;   /* 25px */
$icon-hover-lg: $v-baseline*1.5;       /* 30px */
$icon-hover-xl: $v-baseline*2;         /* 40px */

/* MEDIA QUERIES */
$v-screen-xl: 1602px;
$dashboard-tablet-breakpoint: 992px;
$dashboard-desktop-breakpoint: 1300px;

/* OTHERS */
$box-shadow:          0 0 2px 1px rgba($v-color-text-primary, 0.35);
$box-stronger-shadow: -2px 3px 8px 1px rgba($v-color-text-primary, 0.55);
$box-shadow-top-bar:       0 0.0 5.5px 0 rgba($v-color-text-primary, 0.35);

$list-border: 1px solid $v-color-warm-grey;
$navbar-title-width: 240px;
$dashboard-chart-padding: 30px;



/////*  FADES  */////
@mixin fade-div($start-color, $end-color, $height, $position) {
  $side: 0;
  @if $position == "after" { $side: -1; }
  $_start_color: rgba(red($start-color), green($start-color), blue($start-color), 1+($side));
  $_end_color: rgba(red($end-color), green($end-color), blue($end-color), 0+(-$side));

  &:#{$position} {
    background: -moz-linear-gradient(top, $_start-color 0%, $_end-color 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, $_start-color), color-stop(100%, $_end-color)); /* Chrome,Safari4+ */
    background: linear-gradient(to bottom, $_start-color 0%, $_end-color 100%); /* W3C */
    content:"";
    display: block;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$start-color}', endColorstr='#{$end-color}',GradientType=0 ); /* IE6-9 */
    height: $height;
    @if $position == "before" {
      top: 0;
      bottom: initial;
    } @else {
      top: initial;
      bottom: 0;
    }
    left: 0;
    position: absolute;
    pointer-events: none;
    width: 100%;
    *zoom:1;
    zoom:1;
  }
}
